import {MatrikkelNummer, OrganisasjonsNummer} from '../../utils/dibkSchemas/models/models';
import {Brand} from 'utility-types';
import {getAsyncAxiosInstanceWithIdportenHeader} from './axiosService';

export type AltinnReference = Brand<string, 'AltinnReference'>;
export type ApplicationId = Brand<number, 'ApplicationId'>;
export interface MinEiendomResponse {
  Tinglyste: SelveierProperty[] | [];
  Borettslagsandeler: BorettsProperty[] | [];
}

export interface SelveierProperty {
  Kommune: Kommune;
  Eiendom: TinglystEiendom;
  testReason?: string; //TO KNOW WHY TO TEST PROPERTY
}
interface TinglystEiendom {
  Kommunenummer: string;
  Gardsnummer: number;
  Bruksnummer: number;
  Festenummer: number;
  Seksjonsnummer: number;
  Komplett: MatrikkelNummer;
}
interface Kommune {
  Kommunenummer: string;
  Kommunenavn: string;
}

interface KommuneBorett {
  Kommunenummer?: string;
  Kommunenavn?: string;
}
export interface BorettsProperty {
  Borett: {
    BorettslagId: OrganisasjonsNummer;
    Andelsnummer: number;
  };
  Kommune: KommuneBorett;
  testReason?: string; //TO KNOW WHY TO TEST PROPERTY
}
export type OrderType = 'UaNabovarsel' | 'Byggesoknad' | 'Ferdigattest';

export async function createOrGetUser() {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.get<{Id: string} | null>(`/hentInnloggetBruker/`)
  );
}

export async function fetchOwnedProperties() {
  return getAsyncAxiosInstanceWithIdportenHeader().then((instance) =>
    instance.get<MinEiendomResponse>('/hentMineEiendommer')
  );
}
